<template>
	<div class="leaderboard">
		<TransitionGroup name="fade" appear>
			<h2 :key="1">{{ translate('leaderboard') }}</h2>
			<p :key="2" class="subtitle">{{ translate('leaderboard.subtitle') }}</p>
		</TransitionGroup>

		<TransitionGroup v-if="loaded" name="fade-slide-up" tag="div" class="podium" appear>
			<div
				:key="1"
				class="second"
				style="transition-delay: 0.25s"
				@click="$router.push(`/profile/@${podium[1].username}`)">
				<Avatar :user="podium[1]" :size="130" />
				<p class="name">{{ podium[1].display_name.split(' ')[0] }}</p>
				<Score :override="podium[1].score" />
			</div>
			<div :key="2" class="first" @click="$router.push(`/profile/@${podium[0].username}`)">
				<Avatar :user="podium[0]" :size="170" />
				<p class="name">{{ podium[0].display_name.split(' ')[0] }}</p>
				<Score :override="podium[0].score" />
			</div>
			<div
				:key="3"
				class="third"
				style="transition-delay: 0.5s"
				@click="$router.push(`/profile/@${podium[2].username}`)">
				<Avatar :user="podium[2]" :size="130" />
				<p class="name">{{ podium[2].display_name.split(' ')[0] }}</p>
				<Score :override="podium[2].score" />
			</div>
		</TransitionGroup>

		<div v-if="loaded" class="board">
			<TransitionGroup appear @before-enter="beforeEnter" @enter="enter">
				<div
					v-for="(user, index) in users.slice(3, users.length)"
					:key="index"
					class="spot"
					:data-index="index">
					<div class="number">{{ index + 4 }}.</div>

					<User :user="user" :clickable="true" />

					<Score :override="user.score" />
				</div>
			</TransitionGroup>
		</div>

		<Loader v-if="!loaded" />
	</div>
</template>

<script>
import { db } from '@/lib/supabase'
import { handleError } from '@/utils/utils'
import gsap from 'gsap'

import Avatar from '@/components/Avatar.vue'
import User from '@/components/User.vue'
import Score from '@/components/Score.vue'
import Loader from '@/components/Loader.vue'

export default {
	components: {
		Avatar,
		User,
		Score,
		Loader,
	},

	data() {
		return {
			users: [],
			loaded: false,
			subscriptionLeaderboard: undefined,
		}
	},

	computed: {
		podium() {
			return [...this.users].slice(0, 3)
		},
	},

	async mounted() {
		await this.getLeaderboard()
		this.subscribeLeaderboard()
	},

	destroyed() {
		db.removeSubscription(this.subscriptionLeaderboard)
	},

	methods: {
		async getLeaderboard() {
			const { data, error } = await db.rpc('get_leaderboard')

			if (data) {
				this.users = data
				this.users.sort((a, b) => b.score - a.score)
				this.loaded = true
			} else {
				handleError(error)
			}
		},

		subscribeLeaderboard() {
			this.subscriptionLeaderboard = db
				.from('guesses')
				.on('INSERT', (payload) => {
					const guess = payload.new

					if (guess.correct) {
						for (let user of this.users) {
							if (user.id === guess.user_id) {
								user.score += 100
							}
						}

						this.users.sort((a, b) => b.score - a.score)
					}
				})
				.subscribe()
		},

		beforeEnter(el) {
			el.style.opacity = 0
			el.style.transform = 'translateY(30px)'
		},

		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 0.8,
				onComplete: done,
				delay: 0.75 + el.dataset.index * 0.1,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.leaderboard {
	text-align: center;
}

.podium {
	align-items: flex-end;
	display: flex;
	justify-content: center;
	margin-top: 50px;

	> div {
		cursor: pointer;
		position: relative;

		+ div {
			margin-left: 50px;
		}

		&::before {
			align-items: center;
			background: red;
			border-radius: 100px;
			color: black;
			display: flex;
			font-size: 16px;
			font-weight: 800;
			height: 30px;
			justify-content: center;
			left: 50%;
			position: absolute;
			width: 30px;
			top: 0;
			transform: translate(-50%, -40%);
			z-index: 2;
		}

		&.first {
			&::before {
				background: $gold;
				content: '1';
				font-size: 22px;
				height: 50px;
				width: 50px;
			}

			::v-deep .avatar {
				border: 5px solid $gold;
			}
		}

		&.second {
			&::before {
				background: $silver;
				content: '2';
			}

			::v-deep .avatar {
				border: 5px solid $silver;
			}
		}

		&.third {
			&::before {
				background: $bronze;
				content: '3';
			}

			::v-deep .avatar {
				border: 5px solid $bronze;
			}
		}

		::v-deep .avatar,
		::v-deep .score {
			cursor: pointer !important;
		}

		.name {
			font-size: 24px;
			font-weight: 700;
			margin: 10px 0 5px;
		}
	}
}

.board {
	margin: 50px auto 0;
	max-width: 700px;
	text-align: left;

	.spot {
		align-items: center;
		background: darken(white, 5%);
		border-radius: $border-radius;
		cursor: default;
		display: flex;
		font-size: 16px;
		padding: 10px 15px;

		@include mobile {
			font-size: 14px;
		}

		+ .spot {
			margin-top: 5px;
		}

		.number {
			font-weight: 600;
			margin-right: 10px;
			width: 16px;
		}

		.completed {
			align-items: center;
			display: flex;

			.icon i {
				margin-right: 4px;
			}
		}

		.score {
			align-items: center;
			display: flex;
			margin-left: auto;

			.icon i {
				margin-right: 4px;
			}

			span {
				font-weight: 600;
			}
		}
	}
}

::v-deep .loader {
	margin-top: 100px;
}
</style>
